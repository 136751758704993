@font-face {
  font-family: 'AcuminProBold';
  src: url('/assets/fonts/AcuminProBold.otf');
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProBook';
  src: url('/assets/fonts/AcuminProBook.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AcuminProMedium';
  src: url('/assets/fonts/AcuminProMedium.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProSemibold';
  src: url('/assets/fonts/AcuminProSemibold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProLight';
  src: url('/assets/fonts/AcuminProLight.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProExtraLight';
  src: url('/assets/fonts/AcuminProExtraLight.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProThin';
  src: url('/assets/fonts/AcuminProThin.otf');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProBookItalic';
  src: url('/assets/fonts/AcuminProBookItalic.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AcuminProSemiboldItalic';
  src: url('/assets/fonts/AcuminProSemiboldItalic.otf');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-h {
  font-family: 'AcuminProBold', Sans-serif !important;
  line-height: normal !important;
}

.font-h1 {
  font-family: 'AcuminProBold', Sans-serif !important;
  font-size: 22px;
}

.font-semibold,
.strong,
strong,
button,
a.btn {
  font-family: 'AcuminProSemibold', Sans-serif !important;
}

.font-book,
a:not(.btn) {
  font-family: 'AcuminProBook', Sans-serif !important;
}

input,
.font-regular,
.font-normal,
.fw-normal,
.fw-regular {
  font-family: 'AcuminProMedium', Sans-serif !important;
}

small,
.small,
.font-light,
.fw-light {
  font-family: 'AcuminProLight', Sans-serif !important;
}

.xsmall {
  font-family: 'AcuminProThin', Sans-serif !important;
  font-size: 0.8em !important;
}

.italic-book,
.font-italic {
  font-family: 'AcuminProBookItalic', Sans-serif !important;
}

.question-widget .btn {
  font-family: 'AcuminProSemibold', Sans-serif !important;
  text-transform: uppercase;
  border-width: 2px !important;
}
.question-widget:not(input) {
  font-family: 'AcuminProBookItalic', Sans-serif !important;
}
