:root {
  --black: #000;
  --blue: #0025ff;
  --dark: #323232;
  --gray: #f8f8f8;
  --gray2: #e0e0e0;
  --gray3: #b0b0b0;
  --dark-gray: #979797;
  --green: #038391;
  --green-border: #608281;
  --green2: #a1cbc9;
  --green3: #c0e0db;
  --green4: #d1e9e5;
  --green5: #f2f9f7;
  --orange: #ff8406;
  --orange2: #ffe4c4;
  --pink: #e00490;
  --pink2: #ffc9eb;
  --white: #fff;
  --yellow: #f7d002;
  --yellow2: #fff8d3;
  --yellow3: #f6d110;
  --yellow4: #fffaf4;

  --helper-bg: #f5ffff;
  --helper-border: #038391;

  --header: #fffaf4;
  --footer: #fffaf4;

  --success: #048661;
  --danger: #cf4240;
  --warning: #f7d002;
  --info: #a1cbc9;
}

$primaryC: #038391;
$secondaryC: #060707;
$successC: #048661;
$dangerC: #cf4240;
$warningC: #f7d002;
$infoC: #a1cbc9;
$darkC: #323232;
