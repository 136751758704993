.ng-select,
.ng-select.multiple-select {
  border: 0px;
  border-radius: 0;
  font-family: 'AcuminProMedium' !important;
}
.ng-select .ng-select-container,
.ng-select.multiple-select .ng-select-container {
  height: 50px !important;
  border-radius: 0.375rem;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.ng-select.multiple-select.ng-select-focused:not(.ng-select-opened)
  > .ng-select-container {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #81c1c8;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(3, 131, 145, 0.25);
}
.ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected,
.ng-select.multiple-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  background-color: var(--green4);
}

.ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked:not(.ng-option-selected),
.ng-select.multiple-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked:not(.ng-option-selected) {
  background-color: var(--green5);
}

.ng-select .ng-arrow-wrapper,
.ng-select.multiple-select .ng-arrow-wrapper {
  height: 50px;
  width: 50px;
  padding: 0;
  background-color: black;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow,
.ng-select.multiple-select.ng-select-opened > .ng-select-container .ng-arrow {
  position: absolute !important;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  border: none !important;
  height: 20px !important;
  width: 20px !important;
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  line-height: 1;
  color: #fff;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow::before,
.ng-select.multiple-select.ng-select-opened
  > .ng-select-container
  .ng-arrow::before {
  content: '\f077';
}

.ng-select .ng-arrow,
.ng-select.multiple-select .ng-arrow {
  position: absolute !important;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  border: none !important;
  height: 20px !important;
  width: 20px !important;
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  line-height: 1;
  color: #fff;
}
.ng-select .ng-arrow::before,
.ng-select.multiple-select .ng-arrow::before {
  content: '\f078';
}
// "\f077"
.ng-select:not(.ng-select-opened) .ng-arrow-wrapper:hover .ng-arrow,
.ng-select.multiple-select:not(.ng-select-opened)
  .ng-arrow-wrapper:hover
  .ng-arrow {
  color: #eee;
}
