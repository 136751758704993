@import '/src/assets/css/mixins';

.page-container {
  width: 100vw;
  max-width: 100vw;
  min-height: 100%;
  height: 100%;
  padding: 2em 0;

  @include responsive(sm) {
    padding: 1em 0;
  }
}

.login-container {
  position: relative;
  margin: auto;
  max-width: 1000px !important;
  width: 100%;
  padding: 15px;
}

.full-image {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}

.full-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.full-content {
  flex: 1;
}

// FORM ICON
.input-icon {
  position: relative;

  input {
    padding-left: 40px !important;
  }

  i {
    position: absolute;
    opacity: 0.8;
    left: 10px;
    top: 50%;

    transform: translateY(-50%);
  }
}
//

// Bootstrpa settings
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.widget {
  font-size: 1.2em;
}

.modal-radius {
  border-radius: 2em !important;

  .modal-content {
    border-radius: 2em !important;
  }
}

.w-fit {
  width: fit-content;
}

.modal-content {
  border: none !important;
}
// END Bootstrpa settings

.question-widget {
  background-color: inherit;
  border-radius: 6px;
  margin: 15px 0;
  padding: 1em 0;

  input[type='text'].form-control,
  input[type='email'].form-control {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }

  @include responsive(lg) {
    margin: 1.5em 0;
  }
}

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  max-width: 1215px !important;
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1215px !important;
  }
}
