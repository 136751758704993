.clickable {
  cursor: pointer;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.progress-info {
  margin: auto;
  font-size: 1.35em;
}

// @media (max-width: 767px) {
//   .progress-info {
//     font-size: 1em;
//   } 
// }